import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/Seo/seo"
import HeaderScene from "../components/PageParts/About/HeaderScene"
import Intro from "../components/PageParts/About/Intro"
import Believe from "../components/PageParts/About/Believe"
import TheTeam from "../components/PageParts/About/TheTeam"
import Collaborators from "../components/PageParts/About/Collaborators"
import Community from "../components/PageParts/About/Community"

const AboutPage = props => (
  <Layout location={props.location.pathname}>
    <SEO
      title="Switchback Creative - Designers, developers, strategists, writers and doers."
      description="We have a team with more than 30 years of professional experience. Having worked with hundreds of clients since 2014, our job is to get you seen and heard."
      location={props.location.pathname}
      pageImg={props.data.metaImage.publicURL}
    />
    <HeaderScene />
    <Intro />
    <Believe />
    <TheTeam />
    <Collaborators />
    <Community />
  </Layout>
)

export const aboutQuery = graphql`
  {
    metaImage: file(relativePath: { eq: "switchback-whoweare-meta.jpg" }) {
      publicURL
    }
  }
`

export default AboutPage
