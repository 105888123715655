import React from "react"
import styled from "styled-components"

import { bodyCopy, headlineTwo, colors, fontSizer } from "../../../Utilities"
import SuzyTrevor from "../../../Elements/Images/SuzyTrevor"

const BelieveSection = styled.section`
  position: relative;
  .suzy-trevor-believe {
    position: relative;
    width: 100%;
    height: 40rem;

    @media (min-width: 768px) {
      height: 55rem;
    }

    @media (min-width: 1025px) {
      width: 70vw;
      height: 70vw;
      margin-right: 30vw;
    }

    .st-content {
      position: relative;
      width: 100%;
      padding: 2rem;
      z-index: 5;

      @media (min-width: 768px) {
        position: absolute;
        left: 5rem;
        bottom: 5rem;
        width: 50rem;
        z-index: 5;
      }

      h2 {
        ${headlineTwo};
        color: ${colors.white};
      }

      p {
        ${bodyCopy};
        color: ${colors.white};
      }
    }

    .st-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: ${colors.black};
      opacity: 0.5;
      z-index: 1;
    }
  }

  .values-container {
    width: 100%;
    padding: 5rem 0.5rem;
    background: ${colors.colorPrimary};
    text-align: center;

    @media (min-width: 375px) {
      padding: 5rem 1.5rem;
    }

    @media (min-width: 768px) {
      width: 100%;
      padding: 5rem;
    }

    @media (min-width: 1025px) {
      position: absolute;
      top: 50%;
      right: 0;
      width: 37.5vw;
      padding: 10rem 5rem;
      transform: translate(0, -50%);
      text-align: left;
    }

    p {
      color: ${colors.white};
    }

    &__intro {
      p {
        ${bodyCopy};
        margin: 0;
        color: ${colors.white};
        font-size: 1.8rem;

        @media (min-width: 375px) {
          ${fontSizer(1.8, 2, 76.8, 110, 2)}
        }
      }
    }

    &__be {
      margin-top: 4rem;
      margin-bottom: 4rem;

      p {
        ${headlineTwo};
        ${fontSizer(4, 4.2, 76.8, 110, 3.6)}
        margin-bottom: 0.25rem;
        color: ${colors.white};

        span {
          text-transform: uppercase;
        }
      }
    }

    &__closing {
      p {
        ${bodyCopy};
        margin: 0;
        color: ${colors.white};
      }
    }
  }
`

const Believe = () => {
  return (
    <BelieveSection>
      <div className="suzy-trevor-believe">
        <SuzyTrevor>
          <div className="st-content">
            <h2>What We Believe</h2>
            <p>
              Taking brands to the next chapter through brand storytelling is
              the best way gain life-long fans.
            </p>
          </div>
          <div className="st-overlay" />
        </SuzyTrevor>
      </div>
      <div className="values-container">
        <div className="values-container__intro">
          <p>
            Switchback is for people who
            <br /> believe in the power of their
            <br /> company vision so strongly that
            <br /> they will do anything to share it with
            <br /> the world and connect with those they
            <br /> can help. These values guide us…
          </p>
        </div>
        <div className="values-container__be">
          <p>
            Be <span>Curious</span>
          </p>
          <p>
            Be <span>Brave</span>
          </p>
          <p>
            Be <span>Transparent</span>
          </p>
        </div>
        <div className="values-container__closing">
          <p>
            We do it all with unyielding
            <br /> <span>passion</span> and a sense of
            <br /> <span>adventure</span>.
          </p>
        </div>
      </div>
    </BelieveSection>
  )
}

export default Believe
