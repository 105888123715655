import React, { Component } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import TitleUnderline from "../../Shared/TitleUnderline"
import { MedWrapper, bodyCopy, buttonOne } from "../../../Utilities"

import Compass from "../../../Elements/Images/Compass"

const CommunitySection = styled.section`
  .wrapper {
    position: relative;
    ${MedWrapper};

    @media screen and (-ms-high-contrast: active),
      screen and (-ms-high-contrast: none) {
      margin: 0;
    }
  }

  .graphic {
    position: absolute;
    top: -10rem;
    right: -20%;
    width: 20rem;
    height: 20rem;

    @media (min-width: 768px) {
      top: -10rem;
      right: -7.5rem;
      width: 20rem;
      height: 20rem;
    }
    @media (min-width: 1025px) {
      width: 30rem;
      height: 30rem;
      top: -2rem;
      right: -10rem;
    }
  }

  .community-into {
    width: 100%;
    padding: 5rem 0;
    background: #efefef;

    @media (min-width: 1025px) {
      padding: 5rem 0 10rem;
    }

    &__title {
      width: 100%;
      margin-bottom: 1.5rem;
    }

    &__content {
      width: 100%;
      @media (min-width: 1025px) {
        width: calc(100% - 25%);
        margin-right: 25%;
      }
      p {
        ${bodyCopy};
        color: #585858;
        font-weight: 300;
      }

      a {
        ${buttonOne};
      }
    }
  }
`

class Community extends Component {
  render() {
    return (
      <CommunitySection>
        <div className="community-into">
          <div className="wrapper">
            <div className="community-into__title">
              <TitleUnderline title="Community + Culture" />
            </div>
            <div className="community-into__content">
              <p>
                We believe your community, big or small, is key making your
                company a success. The communities we work in have values that
                run deep about living and working with each other to thrive.
              </p>
              <Link to="/about/in-the-community">In the Community</Link>
            </div>

            <div className="graphic">
              <Compass />
            </div>
          </div>
        </div>
      </CommunitySection>
    )
  }
}

export default Community
