import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

const getData = graphql`
  {
    compass: file(relativePath: { eq: "compass.png" }) {
      childImageSharp {
        fluid(maxWidth: 550) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

const Compass = props => {
  const data = useStaticQuery(getData)
  return (
    <>
      <Img
        fluid={data.compass.childImageSharp.fluid}
        alt="Don't know Where to start? Switchback Creative"
      />
    </>
  )
}

export default Compass
