import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

import TitleUnderline from "../../Shared/TitleUnderline"
import {
  MedWrapper,
  colors,
  colorPrimary,
  headlineThree,
  bodyCopy,
  fontSizer,
} from "../../../Utilities"

const getData = graphql`
  {
    owners: allWordpressWpOwners(sort: { order: ASC, fields: date }) {
      edges {
        node {
          id
          slug
          acf {
            _swb_name
            _swb_title
            _swb_who_you_are {
              point
            }
            _swb_bio
            _swb_bio_excerpt
            _swb_core_value
            _swb_image {
              source_url
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            _swb_hero_image {
              id
            }
          }
        }
      }
    }

    swbTeam: allWordpressWpSwbTeam(sort: { fields: date, order: ASC }) {
      edges {
        node {
          id
          slug
          acf {
            _swb_bio_excerpt
            _swb_bio
            _swb_core_value
            _swb_image {
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            _swb_hero_image {
              id
            }
            _swb_name
            _swb_title
            _swb_who_you_are {
              point
            }
          }
        }
      }
    }
  }
`

const TheTeamSection = styled.section`
  .wrapper {
    ${MedWrapper};
  }

  .team-title {
    width: 100%;
    margin-left: 2rem;
  }

  .team-container {
    ${MedWrapper};
    margin: 0 auto;
    padding: 0;
    justify-content: flex-start;
  }

  .team-link {
    position: relative;
    width: 100%;
    height: 3.2rem;
    a {
      display: block;
      position: relative;
      width: 3.2rem;
      height: 3.2rem;
      margin: 2rem auto;
      opacity: 0.75;
      transition: all 0.3s ease;
      transform: rotate(0deg);
      transform-origin: 50% 50%;

      @media (min-width: 1025px) {
        position: absolute;
        top: -2rem;
        right: 2rem;
        margin: 0;
      }

      &:before,
      &:after {
        position: absolute;
        left: 15px;
        content: " ";
        height: 33px;
        width: 0.5rem;
        background-color: ${colors.colorSecondary};
      }

      &:before {
        transform: rotate(180deg);
      }
      &:after {
        transform: rotate(-90deg);
      }

      &:hover {
        opacity: 1;
        transform: rotate(180deg);

        &:before,
        &:after {
          background-color: ${colors.colorPrimary};
        }
      }
    }
  }
`

const OwnerCard = styled.div`
  position: relative;
  width: 100%;
  margin: 2rem 0;

  @media (min-width: 768px) {
    width: calc(50% - 4rem);
    margin: 3rem 2rem;
  }

  .bio-image {
    position: relative;
    width: 100%;
    margin: 0;

    &__container {
      max-width: 35rem;
      margin: 0 auto;

      @media (min-width: 1025px) {
        margin: 0;
      }
    }
  }

  .points-card {
    position: relative;
    width: 100%;
    max-width: 28rem;
    padding: 2rem;
    margin: -5rem auto 0;
    background: linear-gradient(
      to bottom,
      ${colors.colorSecondary} 0%,
      #075565 83%,
      ${colorPrimary} 100%
    );
    z-index: 10;

    @media (min-width: 1025px) {
      position: absolute;
      top: 50%;
      right: 0;
      width: 100%;
      max-width: 28rem;
      margin: 0;
      transform: translate(0, -50%);
    }

    @media (min-width: 1025px) {
    }

    &__title {
      margin-bottom: 1.5rem;

      h3 {
        ${headlineThree};
        margin: 0;
        color: ${colors.white};
        font-weight: 300;
      }

      p {
        ${bodyCopy};
        ${fontSizer(1.4, 1.6, 76.8, 130, 1.8)}
        margin: 0;
        color: ${colors.white};
        font-weight: 300;
      }
    }

    &__points {
      ul {
        li {
          ${bodyCopy};
          ${fontSizer(1.6, 1.8, 76.8, 130, 1.8)}
          margin: 0;
          margin-bottom: 0.25rem;
          color: ${colors.white};
          font-weight: 300;
        }
      }
    }
  }

  .bio-excerpt {
    margin-top: 4rem;

    p {
      ${bodyCopy};
      ${fontSizer(1.6, 1.8, 76.8, 130, 1.8)}
    }
  }
`

const TeamCard = styled.div`
  width: 100%;
  margin: 2.5rem auto;

  @media (min-width: 768px) {
    width: calc(50% - 4rem);
    margin: 2rem;
  }

  @media (min-width: 1025px) {
    width: calc(33.3333% - 4rem);
    margin: 2rem;
  }

  .team-image {
    max-width: 35rem;
    margin: 0 auto;

    @media (min-width: 768px) {
      max-width: 100%;
    }
  }

  .team-card {
    position: relative;
    max-width: 28rem;
    margin: -5rem auto 0;
    padding: 2rem;
    background: linear-gradient(
      to bottom,
      ${colors.colorSecondary} 0%,
      #075565 83%,
      ${colorPrimary} 100%
    );
    z-index: 5;

    @media (min-width: 768px) {
      max-width: 100%;
      margin: -5rem 2rem 0;
    }

    @media (min-width: 1025px) {
      height: 30rem;
    }

    h3 {
      ${headlineThree};
      margin: 0;
      color: ${colors.white};
      font-weight: 300;
    }

    p {
      ${bodyCopy};
      ${fontSizer(1.4, 1.6, 76.8, 130, 1.8)}
      margin: 0;
      margin-bottom: 1.5rem;
      color: ${colors.white};
      font-weight: 300;
    }

    ul {
      li {
        ${bodyCopy};
        ${fontSizer(1.6, 1.8, 76.8, 130, 1.8)}
        margin: 0;
        margin-bottom: 0.25rem;
        color: ${colors.white};
        font-weight: 300;
      }
    }
  }

  .team-link {
    text-align: center;
    a {
      @media (min-width: 1025px) {
        top: 1rem;
        right: auto;
        left: 50%;
        transform: translate(-50%, 0);
      }

      &:hover {
        opacity: 1;
        transform: rotate(180deg);

        @media (min-width: 1025px) {
          transform: translate(-50%, 0) rotate(180deg);
        }
        &:before,
        &:after {
          background-color: ${colors.colorPrimary};
        }
      }
    }
  }
`

const TheTeam = () => {
  const data = useStaticQuery(getData)
  const {
    owners: { edges: owners },
    swbTeam: { edges: teams },
  } = data
  return (
    <TheTeamSection>
      <div className="wrapper">
        <div className="team-title">
          <TitleUnderline title="The Team" />
        </div>
        <div className="team-container">
          {owners.map(owner => {
            return (
              <OwnerCard key={owner.node.id}>
                <div className="bio-image">
                  <div className="bio-image__container">
                    <Img
                      fluid={
                        owner.node.acf._swb_image.localFile.childImageSharp
                          .fluid
                      }
                      alt={owner.node.acf._swb_image.alt_text}
                    />
                  </div>
                  <div className="points-card">
                    <div className="points-card__title">
                      <h3>{owner.node.acf._swb_name}</h3>
                      <p>{owner.node.acf._swb_title}</p>
                    </div>
                    <div className="points-card__points">
                      <ul>
                        {owner.node.acf._swb_who_you_are.map((item, index) => {
                          return <li key={index}>{item.point}</li>
                        })}
                      </ul>
                    </div>
                  </div>
                  <div className="team-link">
                    <Link to={`/team/${owner.node.slug}`} />
                  </div>
                </div>

                <div
                  className="bio-excerpt"
                  dangerouslySetInnerHTML={{
                    __html: owner.node.acf._swb_bio_excerpt,
                  }}
                />
              </OwnerCard>
            )
          })}

          {teams.map(team => {
            return (
              <TeamCard key={team.node.id}>
                <div className="team-image">
                  {team.node.acf._swb_image && (
                    <Img
                      fluid={
                        team.node.acf._swb_image.localFile.childImageSharp.fluid
                      }
                      alt={team.node.acf._swb_image.alt_text}
                    />
                  )}
                </div>
                <div className="team-card">
                  <div>
                    <h3>{team.node.acf._swb_name}</h3>
                    <p>{team.node.acf._swb_title}</p>
                  </div>
                  <div>
                    <ul>
                      {team.node.acf._swb_who_you_are.map((item, index) => {
                        return <li key={index}>{item.point}</li>
                      })}
                    </ul>
                  </div>
                </div>
                {team.node.slug !== "your-name" && (
                  <div className="team-link">
                    <Link to={`/team/${team.node.slug}`} />
                  </div>
                )}
              </TeamCard>
            )
          })}
        </div>
      </div>
    </TheTeamSection>
  )
}

export default TheTeam
