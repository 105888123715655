import React from "react"
import styled from "styled-components"

import StandardWrapper from "../../Shared/StandardWrapper"
import ScriptTitle from "../../Shared/ScriptTitle"
import BodyCopy from "../../Shared/BodyCopy"

const IntroStyled = styled.div`
  position: relative;
  margin-top: -5rem;
  z-index: 25;

  @media (min-width: 768px) {
    margin-top: -2.5rem;
  }

  @media (min-width: 1025px) {
    margin-top: -5vw;
  }

  .introWrapper {
    @media (min-width: 768px) {
    }
    @media (min-width: 1025px) {
      max-width: 65vw;
      margin-left: 10vw;
      padding: 0;
    }
  }

  .intro-content {
    width: 100%;
    text-align: center;

    @media (min-width: 768px) {
      text-align: left;
    }
  }
`

const Intro = () => {
  return (
    <IntroStyled>
      <StandardWrapper modifierClass="introWrapper">
        <div className="intro-content">
          <ScriptTitle title="<h2 class='title-script'>Designers, developers, strategists, writers and doers.</h2>" />
          <BodyCopy content="<p>Having worked with hundreds of clients since 2014, our job is to get you seen and heard. We genuinely love what we do and are masters of connecting the dots between the hero, your organization and the problem you solve for them.</p><p>We will ensure that you are doing the right things, spending your money effectively, to stir emotion with your marketing and branding.</p>" />
        </div>
      </StandardWrapper>
    </IntroStyled>
  )
}

export default Intro
