import React from "react"
import styled from "styled-components"

import TitleUnderline from "../../Shared/TitleUnderline"
import {
  MedWrapper,
  colors,
  colorPrimary,
  headlineThree,
  bodyCopy,
  fontSizer,
} from "../../../Utilities"

import CollabMaria from "../../../Elements/Images/CollabMaria"
import CollabKate from "../../../Elements/Images/CollabKate"
import CollabLindsey from "../../../Elements/Images/CollabLindsey"
import CollabAfton from "../../../Elements/Images/CollabAfton"
import CollabJen from "../../../Elements/Images/CollabJen"
import CollabJordana from "../../../Elements/Images/CollabJordana"
import CollabJessica from "../../../Elements/Images/CollabJessica"
import CollabInhidden from "../../../Elements/Images/CollabInhidden"

const TheCollabSection = styled.section`
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;

  .wrapper {
    ${MedWrapper};
    justify-content: flex-start;
  }

  .team-title {
    width: 100%;
    margin-left: 2rem;

    p {
      ${bodyCopy};
      ${fontSizer(1.6, 1.8, 76.8, 130, 1.8)}
      margin-top: 2.5rem;
    }
  }

  .team-container {
    ${MedWrapper};
    margin: 0 auto;
    padding: 0;
    justify-content: flex-start;
  }

  .team-link {
    position: relative;
    width: 100%;
    height: 3.2rem;
    a {
      display: block;
      position: relative;
      width: 3.2rem;
      height: 3.2rem;
      margin: 2rem auto;
      opacity: 0.75;
      transition: all 0.3s ease;
      transform: rotate(0deg);
      transform-origin: 50% 50%;

      @media (min-width: 1025px) {
        position: absolute;
        top: -2rem;
        right: 2rem;
        margin: 0;
      }

      &:before,
      &:after {
        position: absolute;
        left: 15px;
        content: " ";
        height: 33px;
        width: 0.5rem;
        background-color: ${colors.colorSecondary};
      }

      &:before {
        transform: rotate(180deg);
      }
      &:after {
        transform: rotate(-90deg);
      }

      &:hover {
        opacity: 1;
        transform: rotate(180deg);

        &:before,
        &:after {
          background-color: ${colors.colorPrimary};
        }
      }
    }
  }
`

const TeamCard = styled.div`
  position: relative;
  width: 100%;
  margin: 2.5rem auto;
  padding-bottom: 1rem;

  @media (min-width: 768px) {
    width: calc(50% - 4rem);
    margin: 2rem;
    padding-bottom: 5rem;
  }

  @media (min-width: 1025px) {
    width: calc(33.3333% - 4rem);
    margin: 2rem;
    padding-bottom: 5rem;
  }

  .team-image {
    max-width: 35rem;
    margin: 0 auto;

    @media (min-width: 768px) {
      max-width: 100%;
    }
  }

  .team-card {
    position: relative;
    max-width: 28rem;
    margin: -5rem auto 0;
    padding: 2rem;
    background: linear-gradient(
      to bottom,
      ${colors.colorSecondary} 0%,
      #075565 83%,
      ${colorPrimary} 100%
    );
    z-index: 5;

    @media (min-width: 768px) {
      max-width: 100%;
      margin: -5rem 2rem 0;
    }

    @media (min-width: 1025px) {
      min-height: 25rem;
    }

    h3 {
      ${headlineThree};
      margin: 0;
      color: ${colors.white};
      font-weight: 300;
    }

    p {
      ${bodyCopy};
      ${fontSizer(1.4, 1.6, 76.8, 130, 1.8)}
      margin: 0;
      margin-bottom: 1.5rem;
      color: ${colors.white};
      font-weight: 300;
    }

    ul {
      li {
        ${bodyCopy};
        ${fontSizer(1.6, 1.8, 76.8, 130, 1.8)}
        margin: 0;
        margin-bottom: 0.25rem;
        color: ${colors.white};
        font-weight: 300;
      }
    }
  }

  .callabBio {
    margin-top: 2rem;

    p {
      ${bodyCopy};
      ${fontSizer(1.6, 1.8, 76.8, 130, 1.8)}
    }
  }

  .team-link {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    text-align: center;
    a {
      @media (min-width: 1025px) {
        top: 1rem;
        right: auto;
        left: 50%;
        transform: translate(-50%, 0);
      }

      &:hover {
        opacity: 1;
        transform: rotate(180deg);

        @media (min-width: 1025px) {
          transform: translate(-50%, 0) rotate(180deg);
        }
        &:before,
        &:after {
          background-color: ${colors.colorPrimary};
        }
      }
    }
  }
`

const Collaborators = () => {
  const team = null
  return (
    <TheCollabSection>
      <div className="wrapper">
        <div className="team-title">
          <TitleUnderline title="Key Collaborators" />
          <p>
            These people give us the ability at our fingertips, to create the
            highest quality, most flexible team possible on a project-by-project
            basis, for your unique needs. Switchback Creative is your company’s
            brand ambassador, we only work with those who are the best in their
            field, to ensure you will reach your goals.
          </p>
        </div>
        <TeamCard>
          <div className="team-image">
            <CollabMaria />
          </div>
          <div className="team-card">
            <div>
              <h3>
                Love From <br />
                Maria
              </h3>
            </div>
            <div>
              <ul>
                <li>Marketing + Communications Strategist</li>
                <li>Writer</li>
              </ul>
            </div>
          </div>
          <div className="callabBio">
            <p>
              At Love From Maria my mission is to raise the voices of those
              doing the hard work to make this world a better place, with love.
              In a noisy world, let’s work together to share a clear and
              compelling story that others want to be a part of.
            </p>
          </div>

          <div className="team-link">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.lovefrommaria.ca/"
            />
          </div>
        </TeamCard>
        <TeamCard>
          <div className="team-image">
            <CollabKate />
          </div>
          <div className="team-card">
            <div>
              <h3>
                Kate Daniel <br />
                Photography
              </h3>
            </div>
            <div>
              <ul>
                <li>
                  Photographer - personal branding & <br />
                  family photography
                </li>
              </ul>
            </div>
          </div>
          <div className="callabBio">
            <p>
              Calgary's business and brand photographer for creative
              entrepreneurs. Kate’s on a mission to capture your life’s fleeting
              moments so that you may treasure the memories for years to come.
              “Simple, real, natural - your life, my lens.”
            </p>
          </div>

          <div className="team-link">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://katedanielphotography.com/"
            />
          </div>
        </TeamCard>

        <TeamCard>
          <div className="team-image">
            <CollabLindsey />
          </div>
          <div className="team-card">
            <div>
              <h3>
                The Write <br />
                Harle
              </h3>
            </div>
            <div>
              <ul>
                <li>
                  Brand and Content <br />
                  Strategist
                </li>
              </ul>
            </div>
          </div>
          <div className="callabBio">
            <p>
              Our core company mission is to get OVERWHELMED leaders aligned
              with the health of their brand to impact connections,
              conversations, and client conversions for real, sustainable
              growth.
            </p>
          </div>

          <div className="team-link">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.thewriteharle.com/"
            />
          </div>
        </TeamCard>

        <TeamCard>
          <div className="team-image">
            <CollabAfton />
          </div>
          <div className="team-card">
            <div>
              <h3>Scribe National</h3>
            </div>
            <div>
              <ul>
                <li>
                  Content strategy and <br />
                  writing
                </li>
              </ul>
            </div>
          </div>
          <div className="callabBio">
            <p>
              Scribe National ignites connections between brands and audiences
              through strategic, authentic storytelling. We are dedicated to
              creating purposeful content that is aligned with your mission,
              amplifies your voice and achieves your goals.
            </p>
          </div>

          <div className="team-link">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.scribenational.ca/"
            />
          </div>
        </TeamCard>

        <TeamCard>
          <div className="team-image">
            <CollabJen />
          </div>
          <div className="team-card">
            <div>
              <h3>
                Jen Roney <br />
                Content + Craft
              </h3>
            </div>
            <div>
              <ul>
                <li>
                  Content Marketing Writer <br />
                  Copywriter
                </li>
              </ul>
            </div>
          </div>
          <div className="callabBio">
            <p>
              My specialty is crafting content to engage your audience, build
              trust, and grow customer loyalty over time, using just the right
              blend of story and strategy. With over a decade of experience, I
              can get your content off the back burner.
            </p>
          </div>

          <div className="team-link">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://jenroney.ca"
            />
          </div>
        </TeamCard>

        <TeamCard>
          <div className="team-image">
            <CollabJordana />
          </div>
          <div className="team-card">
            <div>
              <h3>Jordana Clark</h3>
            </div>
            <div>
              <ul>
                <li>Graphic and Web Designer</li>
              </ul>
            </div>
          </div>
          <div className="callabBio">
            <p>
              Jordana loves using interesting colour pallets and working through
              all ideas to achieve the best results. She enjoy's creating and
              admiring awesome designs that others have done to inspire her
              design life.
            </p>
          </div>
        </TeamCard>

        <TeamCard>
          <div className="team-image">
            <CollabJessica />
          </div>
          <div className="team-card">
            <div>
              <h3>Jessica Jaithoo - Sweet Talk Media</h3>
            </div>
            <div>
              <ul>
                <li>Digital Marketing + Communications Strategist</li>
              </ul>
            </div>
          </div>
          <div className="callabBio">
            <p>
              At Sweet Talk, we promise to make your marketing sweet, simple and
              successful! We do this by analyzing current results, taking a look
              at your existing actions & habits and tweaking your strategies for
              desired outcomes.  We believe in leading with positivity and
              leaving a bit of sparkle along the way!
            </p>
          </div>
          <div className="team-link">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.sweettalkmediainc.ca/"
            />
          </div>
        </TeamCard>

        <TeamCard>
          <div className="team-image">
            <CollabInhidden />
          </div>
          <div className="team-card">
            <div>
              <h3>Inhidden Media</h3>
            </div>
            <div>
              <ul>
                <li>Video Production Company</li>
              </ul>
            </div>
          </div>
          <div className="callabBio">
            <p>
              At Inhidden Media, we believe in the power of video to change the
              world, and we're passionate about using our skills to create
              quality content that makes a difference. We love bringing stories
              and messages to life to help companies and brands connect with
              their customers in a more meaningful way.
            </p>
          </div>
          <div className="team-link">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.inhidden.com/"
            />
          </div>
        </TeamCard>
      </div>
    </TheCollabSection>
  )
}

export default Collaborators
